import type { GatsbyConfig } from 'gatsby'

const config: GatsbyConfig = {
  siteMetadata: {
    title: 'wicadu',
    titleTemplate: '%s | wicadu',
    description: 'Administra tu tienda en línea. Llega a más clientes y ve crecer tus ventas con wicadu',
    siteUrl: process.env.OAUTH_WEBAPP_HOST,
    url: process.env.OAUTH_WEBAPP_HOST,
    image: '/images/logos/favicon.png',
    twitterUsername: '@wicaduapp'
  },
  plugins: [
    {
      resolve: 'gatsby-plugin-emotion',
      options: {
        sourceMap: true,
        cssPropOptimization: true,
      },
    },
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'wicadu',
        short_name: 'wicadu',
        start_url: '/',
        background_color: '#056cf2',
        theme_color: '#056cf2',
        display: 'standalone',
        icon: 'static/images/logos/favicon.png',
        crossOrigin: 'use-credentials'
      }
    },
    {
      resolve: 'gatsby-plugin-alias-imports',
      options: {
        alias: {
          '@components': 'src/components',
          '@screens': 'src/screens',
          '@containers': 'src/containers',
          '@ts-enums': 'src/ts/enums',
          '@ts-types': 'src/ts/types',
          '@HOCs': 'src/HOCs',
          '@hooks': 'src/hooks',
          '@images': 'static/images',
          '@routes': 'src/routes',
          '@utils': 'src/utils',
          '@mocks': 'src/__mocks__'
        },
        extensions: [
          'ts',
          'tsx'
        ]
      }
    },
    {
      resolve: `gatsby-plugin-env-variables`,
      options: {
        allowList: [
          'VERSION',
          'OAUTH_WEBAPP_HOST',
          'OAUTH_SERVER_HOST',
          'HELP_WEBAPP_HOST',
          'SENTRY_DSN',
          'SENTRY_ENVIRONMENT',
          'SENTRY_RELEASE',
          'SENTRY_PROJECT_NAME',
        ]
      }
    },
    {
      resolve: '@sentry/gatsby',
    }
  ]
}

export default config
