import React, { Fragment } from 'react'
import SEO from '@components/SEO'
import { Typography } from '@wicadu/arepa/ui'

function Version () {
  return (
    <Fragment>
      <SEO />

      {process.env.VERSION && (
        <Typography type='helper' align='center' size={14}>
          v {process.env.VERSION}
        </Typography>
      )}
    </Fragment>
  )
}

export default Version
